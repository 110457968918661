<template src="./index.html" />

<script>
import {
  BBreadcrumb, BButton, BCard, BCol, BContainer, BFormSelect, BOverlay, BPagination, BRow,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/locale/vi.js'
import 'vue2-datepicker/index.css'
import { VueGoodTable } from 'vue-good-table'
import '@core/scss/vue/libs/vue-good-table.scss'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import FormBuilding from '@/views/admin/category/building/FormBuilding.vue'

import 'bootstrap-vue/dist/bootstrap-vue-icons.min.css'
import { hasPermissionForResource, isSystemAdmin } from '@/utils'
import { PermissionCode, ResourceCode } from '@/const/code'
import { getUser } from '@/auth/utils'
import { STATUSES } from '@/const/status'

export default {
  name: 'Title',
  components: {
    BBreadcrumb,
    BButton,
    BCard,
    BCol,
    BContainer,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    ValidationProvider,
    ValidationObserver,
    DatePicker,
    VueGoodTable,
    vSelect,
    FormBuilding,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      user: getUser(),
      loading: true,
      serverParams: {
        currentPage: 1,
        itemsPerPage: 10,
        buildingName: null,
        status: null,
      },
      editMode: false,
      fields: [
        {
          field: 'buildingName',
          filterOptions: {
            enabled: true,
            placeholder: 'Tòa nhà',
            trigger: 'enter',
          },
          label: 'Tòa nhà',
          sortable: true,
          thClass: 'text-center',
        },
        {
          field: 'organizationName',
          label: 'Tổ chức',
          sortable: false,
          thClass: 'text-center',
        },
        {
          field: 'status',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            filterDropdownItems: STATUSES.map(status => ({ value: status.value, text: status.label })),
            placeholder: 'Chọn trạng thái',
          },
          label: 'Trạng thái',
          sortable: false,
        },
        {
          field: 'orderNo',
          label: 'Thứ tự',
          sortable: true,
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          field: 'action',
          label: '',
          tdClass: 'text-center',
          sortable: false,
        },
      ],
      row_selected: null,
      dataBuilding: {},
    }
  },
  computed: {
    ...mapGetters({
      buildings: 'building/buildings',
      total: 'building/total',
    }),
    creatable() {
      return this.isSystemAdmin() || hasPermissionForResource(PermissionCode.CREATE, ResourceCode.BUILDING)
    },
    updatable() {
      return this.isSystemAdmin() || hasPermissionForResource(PermissionCode.UPDATE, ResourceCode.BUILDING)
    },
    deletable() {
      return this.isSystemAdmin() || hasPermissionForResource(PermissionCode.DELETE, ResourceCode.BUILDING)
    },
  },
  async created() {
    await this.getData()
  },
  methods: {
    ...mapActions({
      getBuildings: 'building/getBuildings',
      getInit: 'building/getInit',
      createBuilding: 'building/createBuilding',
      deleteBuilding: 'building/deleteBuilding',

    }),
    isSystemAdmin,
    updateParams(newProps) {
      this.serverParams = { ...this.serverParams, ...newProps }
    },
    async onPageChange(params) {
      this.updateParams({ currentPage: params.currentPage })
      await this.getData()
    },
    async onPerPageChange(params) {
      this.updateParams({ currentPage: 1, itemsPerPage: params.currentPerPage })
      await this.getData()
    },
    async onSortChange(param) {
      if (param.length > 0) {
        const { field, type } = param[0]
        field === 'buildingName' ? this.updateParams({ sort: `1_${type}` }) : this.updateParams({ sort: `2_${type}` })
        await this.getData()
      }
    },
    async onColumnFilter(params) {
      const { columnFilters } = params
      this.updateParams(columnFilters)
      await this.getData()
    },
    showToast(title, icon, variant, text) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title,
          icon,
          variant,
          text,
        },
      })
    },

    async editItem(item) {
      this.row_selected = item
      await this.$refs.DetaiTitlePopupRef.showModal(item)
    },

    async deleteItem(item) {
      this.$swal
        .fire({
          title: 'Xóa bản ghi này?',
          text: 'Bạn sẽ không thể khôi phục dữ liệu này sau khi xóa !',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: '<i class="fa-solid fa-check"></i> Đồng ý',
          cancelButtonText: '<i class="fa-solid fa-times"></i> Hủy',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        })
        .then(async result => {
          if (result.isConfirmed) {
            const rs = await this.deleteBuilding(item.buildingId)
            if (rs) {
              await this.getData()
              this.showToast('Đã xóa thành công!', 'CheckIcon', 'success', '')
            } else {
              this.showToast('Có lỗi xẩy ra', 'XCircleIcon', 'warning', '')
            }
          }
        })
    },

    async btnSearchClick() {
      this.loading = true
      await this.getData()
    },

    openModal() {
      this.dataBuilding = {}
      this.$refs['modal-building'].show()
    },

    editBuilding(data) {
      this.dataBuilding = data
      this.$refs['modal-building'].show()
    },

    async saveDataBuilding(data) {
      const dataSend = {
        id: data.buildingId,
        name: data.buildingName,
        orderNo: data.orderNo,
        status: data.status,
        organizationId: data.organization,
      }
      const temp = await this.createBuilding(dataSend)
      if (temp) {
        this.$bvModal.hide('modal-building')
        this.showToast('Cập nhật thành công!', 'CheckIcon', 'success', '')
        await this.getData()
      }
    },

    getStatusName(id) {
      return STATUSES.find(status => status.value === id).label
    },

    async getData() {
      await this.getBuildings(this.serverParams)
    },
  },
}
</script>
<style>
.v-select.drop-up.vs--open .vs__dropdown-toggle {
  border-radius: 0 0 4px 4px;
  border-top-color: transparent;
  border-bottom-color: rgba(60, 60, 60, 0.26);
}

[data-popper-placement="top"] {
  border-radius: 4px 4px 0 0;
  border-top-style: solid;
  border-bottom-style: none;
  box-shadow: 0 -3px 6px rgba(0, 0, 0, 0.15);
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
